.full_center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
}

.full_center img {
    max-height: 50vh;
}

.full_center p {
    color: white;
    font-size: 2rem;
}

.controls svg{
    margin: 0 3rem;
}

.btn_icon_pause{
    padding: 1rem;
    color: #FFF;
    border: 2px solid #CCC;
    border-radius: 3rem;
}