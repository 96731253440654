.App {
    padding: 1rem;
    text-align: center;
    background-color: #282c34;
}

a, a:visited, a:active {
    text-decoration: none;
    color: white;
}

/*@media (prefers-reduced-motion: no-preference) {*/
/*  .App-logo {*/
/*    animation: App-logo-spin infinite 20s linear;*/
/*  }*/
/*}*/

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-template-rows: auto auto auto auto;
    column-gap: 10px;
    row-gap: 15px;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.fixed_player_footer {
    position: fixed;
    height: 10rem;
    width: 10rem;
    bottom: -3rem;
    left: calc(50% - 5rem);
    border-radius: 5rem;
    overflow: hidden;
    border: 5px solid #FFF;
}

.fixed_player_footer img {
    max-height: 100%;
    max-width: 100%;
}

.tile-container {
    display: flex;
    flex-wrap: wrap;
    padding: 2rem;
}

.tile-container .tile-artist, .tile-container .tile-album {
    width: calc((100vw - 4rem) * 100 / 5 / 100);
    padding: 0.6rem;
}

.tile-container .tile-artist a, .tile-container .tile-album a {
    display: block;
    max-width: 100%;
}

.tile-container .tile-artist a img, .tile-container .tile-album a img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.btn_back {
    background-color: #FFF;
    color: #3758a0;
    position: fixed;
    top: 1rem;
    right: 1rem;
    border-radius: 3rem;
    height: 6rem;
    width: 6rem;
    padding: 0.4rem;
}

.act_wrapper{
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.act_wrapper_row{
    display: flex;
    align-items: center;
    justify-content: center;
}